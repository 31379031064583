import React, { useState, useRef, useEffect } from 'react';
import SwiperCore, { Autoplay, Zoom, Mousewheel, Keyboard, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import projects from "./data/projects.json";
import './App.css';

// Install Swiper modules
SwiperCore.use([Autoplay, Zoom, Mousewheel, Keyboard, Pagination, Navigation]);

export default function App() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const swiperRef = useRef(null);

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.activeIndex);
    setShowMore(false); // Reset showMore when slide changes
  };

  const handleShowMore = () => {
    setShowMore(true);
  };

  const handleShowLess = () => {
    setShowMore(false);
  };

  const currentProject = projects[currentIndex];

  const descriptions = [
    currentProject.desc,
    currentProject.desctwo,
    currentProject.descthree,
    currentProject.descfour,
    currentProject.descfive,
    currentProject.descsix,
  ];

  // Filter out the first description for "Show More" functionality and non-empty descriptions
  const descriptionsToShow = descriptions.slice(1).filter(desc => desc);

  // Preload images
  useEffect(() => {
    projects.forEach(project => {
      project.links.forEach(link => {
        const img = new Image();
        img.src = link;
      });
    });
  }, []);

  return (
    <>
      <div className='contact-container gray'>
        <a className="button" href="mailto:info@stefroelings.com">Contact</a>  
      </div>
      <div className="swiper-container">
        <Swiper
          ref={swiperRef}
          direction={'vertical'}
          className="mySwiper swiper-v"
          spaceBetween={0}
          keyboard={{
            enabled: true,
          }}
          speed={550}
          mousewheel={true}
          pagination={{
            type: 'fraction',
            clickable: true,
          }}
          onSlideChange={handleSlideChange}
          modules={[Mousewheel, Keyboard, Pagination, Navigation]}
        >
          {projects.map((project, index) => (
            <SwiperSlide key={index}>
              <Swiper
                className="mySwiper2 swiper-h"
                spaceBetween={0}
                keyboard={{
                  enabled: true,
                }}
                speed={1}
                loop={true}
                zoom={true}
                // autoplay={{
                //   delay: 1000,
                //   disableOnInteraction: true,
                // }}
                navigation={true}
                modules={[Autoplay, Zoom, Keyboard, Pagination, Navigation]}
              >
                {project.links.map((link, linkIndex) => (
                  <SwiperSlide key={linkIndex}>
                    <img src={link} alt={`Slide ${linkIndex + 1}`} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className='swiper-title'>
          <span>{currentProject.title}</span><br/>
          <span className='gray'>{currentProject.subtitle}</span>      
        </div>
        <div className='swiper-desc gray'>
          <span className='black'>{currentProject.desc}</span>
          {currentProject.desctwo && !showMore && (
            <>
              <br/><br/> 
              <button className="more-button" onClick={handleShowMore}>More</button>
            </>
          )}
          {showMore && (
            <>
              <TransitionGroup component={null}>
                {descriptionsToShow.map((desc, index) => (
                  <CSSTransition
                    key={index}
                    timeout={300}  // Shorter timeout for smoother transition
                    classNames="fade"
                  >
                    <div className="fade-item black">
                      <br/>{desc}
                    </div>
                  </CSSTransition>
                ))}
              </TransitionGroup>
              <br/>
              <span >{currentProject.skills}</span>
              <br/>
              <br/>
              <button className="less-button" onClick={handleShowLess}>Less</button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
